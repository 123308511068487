/* eslint-disable */
<template>
  <v-card style="height: fit-content">
    <h3>{{ title }}</h3>
    <span>
      {{ description }}
    </span>

    <v-spacer />
    <v-list three-line class="transparent" v-if="activities.length > 0">
      <v-data-table
        :items-per-page="10"
        group-by="date"
        class="elevation-1"
        :items="activities"
      >
        <template v-slot:[`group.header`]="{ group }">
          <th colspan="2">
            {{ group }}
          </th>
        </template>
        <template v-slot:item="{ item }">
          <v-card class="my-2">
            <v-list-item @click="() => {}">
              <v-list-item-avatar>
                <img :src="avatar.route" />
              </v-list-item-avatar>
              <v-list-item-content class="mx-3" style="font-size: 0.8rem">
                <v-list-item-title v-html="item.model" />
                <v-list-item-subtitle v-html="item.action" />
                <v-list-item-subtitle v-html="item.id" />
                <v-list-item-subtitle
                  v-html="'Hora: ' + item.timestamp.split('T')[1].split('.')[0]"
                />
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
      </v-data-table>
    </v-list>
    <v-list three-line class="transparent" v-else>
      <template v-for="i in 10">
        <v-skeleton-loader :key="i" v-bind="attrs" type="list-item-avatar, divider" />
      </template>
    </v-list>
  </v-card>
</template>
<script>
  /* eslint-disable */
  import { handleActivityEs } from '@/mixins/activities'

  export default {
    props: { user: Object },
    data() {
      return {
        attrs: {
          class: 'mb-1',
          boilerplate: false,
          elevation: 1,
        },
        userActivities: [],
        title: ' Mi Actividad',
        description: 'Resumen de las ultimas 50 operaciones realizadas en la plataforma',
      }
    },
    computed: {
      userId() {
        if (this.$session.get('userId') && this.$session.get('userId') != 0) {
          return this.$session.get('userId')
        }
      },
      avatar() {
        if (this.$session.get('userAvatar') && this.$session.get('userAvatar') != {}) {
          return this.$session.get('userAvatar')
        }
      },

      activities() {
        if (this.userActivities.length > 0) {
          let activities = []

          this.userActivities.forEach((activity) => {
            let simpleActivity = handleActivityEs(activity)
            simpleActivity.date = new Date(
              simpleActivity.timestamp.split('T')[0]
            ).toLocaleDateString('es-VE', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })
            activities.push(simpleActivity)
          })
          return activities
        } else {
          return []
        }
      },
    },
    created() {
      this.getActivities()
    },
    methods: {
      async getActivities() {
        axios.get('users/' + this.userId + '?activities=1').then((response) => {
          this.userActivities = response.data.activities.slice(0, 100)
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-list-item__title {
    align-self: center;
    font-size: 0.9rem;
  }
  .v-list-item__subtitle {
    font-size: 0.75rem;
  }
</style>
