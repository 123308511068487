export function handleActivityEs(activity) {
  const model = `${getModelEs(activity.subject_type)} #${activity.subject_id}`
  const action = `Acción realizada: ${getDescriptionEs(activity.description)}`
  const id = `Identificador: ${activity.id}`
  const timestamp = activity.created_at
  const changes = activity.changes
  return { id, model, action, timestamp, changes}
}
function getModelEs(modelPath) {
  switch (modelPath) {
    case 'App\\Models\\Activity': return 'Actividad'
    case 'App\\Models\\Advertisement': return 'Publicidad'
    case 'App\\Models\\AdvertisementCountry': return 'Paises de una Publicidad'
    case 'App\\Models\\AdvertisementStats': return 'Estadisticas de una Publicidad'
    case 'App\\Models\\Category': return 'Categoría'
    case 'App\\Models\\City': return 'Ciudad'
    case 'App\\Models\\Company': return 'Empresa'
    case 'App\\Models\\CompanyData': return 'Asignación de datos de Empresa'
    case 'App\\Models\\Country': return 'País'
    case 'App\\Models\\Data': return 'Dato de la ficha de Empresa'
    case 'App\\Models\\Faq': return 'Preguntas y Respuestas'
    case 'App\\Models\\FormRequest': return 'Solicitud en el Formulario'
    case 'App\\Models\\Publication': return 'Publicación'
    // case 'App\\Models\\Rating': return ''
    case 'App\\Models\\Upload': return 'Archivo multimedia'
    case 'App\\Models\\User': return 'Usuario'
    case 'App\\Models\\Word': return 'Término del Glosario'
    default: return '*'

  }
}
function getDescriptionEs(desc) {
  const strings = desc.split('_')
  switch (strings[0]) {
    case 'created': return 'Creación'
    case 'updated': return 'Actualización'
    case 'deleted': return 'Sustracción'
    default: return '*'

  }
}
