<template>
  <v-card style="height: fit-content">
    <v-data-table
      dense
      :headers="headers"
      :items="items"
      :items-per-page="20"
      no-data-text="No se encontraron franquicias en el intervalo seleccionado"
      loading-text="Buscando Franquicias"
      items-per-page-text="Items por página"
      item-key="name"
      :disable-sort="true"
      class=""
    >
      <template v-slot:top>
        <h3>Nuevas Franquicias</h3>
        <v-row>
          <v-col cols md="4" lg="4" class="m-1">
            <v-text-field
              type="date"
              hide-details="auto"
              label="Desde"
              v-model="firstDay"
            >
            </v-text-field>
          </v-col>
          <v-col cols md="4" lg="4" class="m-1">
            <v-text-field
              type="date"
              hide-details="auto"
              label="Hasta"
              v-model="lastDay"
            ></v-text-field>
          </v-col>
          <v-col
            class="m-1"
            style="
              display: flex;

              align-items: flex-end;
            "
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#003a56"
                  dark
                  max-width="50px"
                  height="40px"
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getCompanies()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.categories`]="{ item }">
        <v-chip
          class="m-1"
          color="#003A56"
          outlined
          pill
          small
          v-for="(category, id) in item.categories"
          :key="id"
        >
          {{ category.name }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    name: 'NewFranchises',
    created() {
      this.getDate()
      this.getCompanies()
    },
    data() {
      return {
        items: [],
        firstDay: '',
        lastDay: '',
        headers: [
          {
            text: 'Nombre',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Categorias', value: 'categories' },
        ],
      }
    },
    props: {},
    methods: {
      getDate() {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth()
        this.firstDay = new Date(year, month, 1).toISOString().split('T')[0]
        this.lastDay = new Date(year, month + 1, 0).toISOString().split('T')[0]
      },
      async getCompanies() {
        let dateString = ''
        if (this.firstDay != '' && this.lastDay != '') {
          dateString = '&startAt=' + this.firstDay + '&endAt=' + this.lastDay
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'companies?limit=300&order=created_at&by=desc' +
              dateString
          )
          .then((response) => {
            this.items = response.data
          })
      },
    },
  }
</script>

<style lang="scss" scoped></style>
