<template>
  <div class="dashboard-content" data-app>
    <!-- Filters -->
    <div class="filters">
      <v-row style="width: 100%">
        <h4 style="margin: 0; align-self: center">Aplicar Filtro</h4>
        <v-col cols="12" lg="3" md="4" sm="6">
          <multiselect
            track-by="id"
            label="name"
            deselect-label=""
            select-label=""
            placeholder="Selecciona un pais"
            v-model="filters.countryId"
            :options="countries"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="false"
          ></multiselect>
        </v-col>
        <v-col>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#003a56"
                style="align-self: center"
                dark
                class="px-1 py-1"
                @click.prevent="
                  filterActivated = true
                  getCompanies()
                  getUsers()
                "
                ><v-icon dark> mdi-filter </v-icon>
              </v-btn>
            </template>
            <span>Filtrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </div>
    <!-- Notice -->
    <div v-if="!hi" class="row">
      <div class="col-md-12 mb-4">
        <div class="alert alert-success alert-dismissible fade show" role="alert">
          <span class="alert-inner--icon"><i class="ni ni-like-2"></i></span>
          <span class="alert-inner--text"
            ><strong>¡Hola!</strong>, ¿Qué quieres hacer hoy?</span
          >
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div class="row">
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ companies.length }}</h2>
              <p class="text-muted mt-2 mb-0">Empresas Registradas</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ users.length }}</h2>
              <p class="text-muted mt-2 mb-0">Usuarios</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">23</h2>
              <p class="text-muted mt-2 mb-0">Paises</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ advertisements.length }}</h2>
              <p class="text-muted mt-2 mb-0">Publicidades activas</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ franchises.length }}</h2>
              <p class="text-muted mt-2 mb-0">Franquicias</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ asociations.length }}</h2>
              <p class="text-muted mt-2 mb-0">Asociaciones de Franquicias</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ consultants.length }}</h2>
              <p class="text-muted mt-2 mb-0">Consultores</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ providers.length }}</h2>
              <p class="text-muted mt-2 mb-0">Proveedores</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Item -->
      <div class="col-lg-3 col-md-6">
        <div class="card mb-4">
          <div class="card-body text-center">
            <i class="i-Add-User"></i>
            <div class="py-1">
              <h2 class="text-primary mb-2">{{ realEstateOffer.length }}</h2>
              <p class="text-muted mt-2 mb-0">Oferta Inmobiliaria</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row style="justify-content: space-evenly">
      <last-activities class="col-md-4 m-1" />
      <admin-new-franchises class="col-lg-7 m-1"></admin-new-franchises>
    </v-row>
  </div>
</template>

<script>
  import LastActivities from '@/components/globalFrontendComponents/LastActivities.vue'
  import AdminNewFranchises from '../../components/globalFrontendComponents/AdminNewFranchises.vue'
  export default {
    components: { LastActivities, AdminNewFranchises },
    data() {
      return {
        hi: false,
        loading: true,
        userActivities: [],
        roles: [],
        users: [],
        advertisements: [],
        companies: [],
        franchises: [],
        asociations: [],
        consultants: [],
        providers: [],
        realEstateOffer: [],
        filterActivated: false,
        countries: [{ id: null, name: 'Todos' }],
        filters: {
          countryId: null,
        },
      }
    },
    created() {
      this.checkHi()
      this.checkPermissions()
      this.getCountries()
      this.getCompanies()
      this.getUsers()
      // this.getAdvertisements()
    },
    methods: {
      checkHi() {
        if (localStorage.getItem('hi')) {
          this.hi = false
          this.sayHi()
        } else {
          this.hi = true
        }
      },

      sayHi() {
        localStorage.setItem('hi', true)
      },

      checkPermissions() {
        if (this.$session.get('roles')) {
          this.$session.get('roles').forEach((role) => {
            this.roles.push(role.name)
          })

          if (!this.roles.includes('admin', 'super_admin')) {
            this.$router.push('/admin/perfil')
          }
        } else {
          this.$router.push('/')
        }
      },
      async getCountries() {
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300')
          .then((response) => {
            this.countries = this.countries.concat(response.data)
          })
      },
      async getUsers() {
        this.loading = true
        let countryString = ''
        if (this.filterActivated) {
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
        }
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'users?limit=1000' + countryString)
          .then((response) => {
            this.users = response.data
            this.loading = false
          })
      },

      async getCompanies() {
        this.loading = true
        let countryString = ''
        if (this.filterActivated) {
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY + 'companies?limit=10000' + countryString
          )
          .then((response) => {
            this.companies = response.data
            this.franchises = this.companies.filter((company) => company.type == 0)
            this.asociations = this.companies.filter((company) => company.type == 1)
            this.consultants = this.companies.filter((company) => company.type == 2)
            this.providers = this.companies.filter((company) => company.type == 3)
            this.realEstateOffer = this.companies.filter((company) => company.type == 4)
            this.loading = false
          })
      },
      async getAdvertisements() {
        this.loading = true
        let countryString = ''
        if (this.filterActivated) {
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'advertisements?limit=10000' +
              countryString
          )
          .then((response) => {
            this.advertisements = response.data
            this.loading = false
          })
      },
    },
  }
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .card-body {
    flex: 1 1 auto;
    padding: 5px;
  }
</style>
